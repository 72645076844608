import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastAnim = () => {
    return (
        <>
            <ToastContainer />
        </>
    )
}

export default ToastAnim;